.nice-number-input {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 24px !important;
  }
  .mdc-floating-label {
    padding-top: 24px !important;
  }

  .mdc-floating-label.mdc-floating-label--float-above {
    padding-top: 0px !important;
  }
}


//mdc-floating-label mat-mdc-floating-label ng-tns-c508571215-14 ng-star-inserted mdc-floating-label--float-above
//mdc-floating-label mat-mdc-floating-label ng-tns-c508571215-12 ng-star-inserted
