.globalSearchBox {
  line-height: 14px;
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 10px;
    padding-bottom: 6px;
    min-height: 40px;
  }

  .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
    border-radius: 0 !important;
    border: 0;
  }

  .mdc-notched-outline__notch {
    border-radius: 0 !important;
    border: 0;
  }
}
