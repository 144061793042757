@import "../theme_vars";

.table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
}

table.table {
  margin-bottom: 0;
}

table.table thead th, table.table tr.thead th {
  background-color: $bar_bg_color;
  vertical-align: middle;
}

table.table th.firstspacer {
  width: 20px;
}

table.table th, table.table .th {
  white-space: nowrap;
  text-align: left;
  color: #94a2a9;
  font-size: 13px;
  padding: 5px;
}

table.table tr.grouptitle td {
  color: grey;
  font-weight: bold;
  font-style: italic;
  padding: 8px;
}


table.table td {
  color: $font_color_2;
  padding: 5px;
}

table.table tbody tr td {
  border-bottom: 1px solid $border_color;
}

table.table.noborders tbody tr td, table.table.noborders tbody tr th {
  border-top: none;
  border-bottom: none;
}

/*
table.table tbody tr:not(.grouptitle):hover td {
  background-color: $hover_bg_color;
}
*/

table.table.mobile td {
  padding: 1px 5px;
}

table.table.mobile th {
  vertical-align: middle;
  padding: 1px 5px;
}

table.mobile th {
  background-color: $bar_bg_color;
  font-weight: normal;
}

table.table button.mat-mdc-icon-button {
  height: 25px;
  line-height: 25px;
}

table.table {
  > tr, > tbody > tr {
    > td {
      vertical-align: middle !important;
    }
  }
}

table.table.table-valtop {
  > tr, > tbody > tr {
    > td {
      vertical-align: top !important;
    }
  }
}

table.table-striped {
  > tr:nth-child(odd), > tbody > tr:nth-child(odd) {
    background-color: $striped_bg_color;
  }
}

table.tablebody-striped {
  > tbody:nth-child(odd) > tr {
    background-color: $striped_bg_color;
  }
}

table.table {
  tr {
    background-color: white;
  }
  tr.noborderbottom {
    td, th {
      border-bottom: 0;
    }
  }

  tr.nobordertop {
    td,th {
      border-top: 0;
    }
  }
}

table.table-body-striped {
  > tbody:nth-child(odd) > tr {
    background-color: $striped_bg_color;
  }
}

table.table-striped2 {
  > tr.striped2:nth-child(odd), > tbody > tr.striped2:nth-child(odd) {
    background-color: $striped_bg_color;
  }
}

/*
table.table-striped, table.table-striped2 {
  tr {
    th {
      background-color: white;
    }
  }
}
*/

table tr.spacer {
  background-color: darkgray;

  > td, > tbody > tr {
    height: 3px;
    line-height: 3px;
    padding: 0;
  }
}

table.table-hover {
  > tr:hover, > tbody > tr:hover {
    background-color: $hover_bg_color;
  }
}

table.table-body-hover {
  > tbody:hover > tr {
    background-color: $hover_bg_color;
  }
}

table.table-hover2 {
  > tr.hover2:hover, > tbody > tr.hover2:hover {
    background-color: $hover_bg_color;
  }
}

table.table-hover {
  tr.spacer:hover {
    background-color: $border_color2;

    td {
      height: 2px;
      line-height: 2px;
    }
  }
}

