mat-mdc-form-field.has-error {
  .mat-mdc-form-field-underline {
    background: red;
  }
}

mat-mdc-form-field.nomargin.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
  margin: 0;
}

mat-mdc-form-field.whitebg {
  .mat-mdc-form-field-flex > div.mat-mdc-form-field-outline {
    background-color: white;
  }
}

mat-mdc-form-field .focusbuttons {
  display: none;
}

mat-mdc-form-field.mat-mdc-focused .focusbuttons {
  display: inline-block;
}

.thead {
  .mat-mdc-checkbox {
    .mat-mdc-checkbox-frame {
      border-color: #94a2a9;
    }
  }
}


.mat-mdc-select-panel-wrap {
  .mat-mdc-option {
    height: auto !important;

    .mat-mdc-option-text {
      height: auto;
      white-space: normal;
    }
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  /*--mat-mdc-form-field-label-transform: translateY(-25px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.8))*/
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  /*top: 21px;*/
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label.mdc-floating-label--float-above {
  /*--mat-mdc-form-field-label-transform: translateY(-25px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.8))*/
}


.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #0000001f;
}

.mat-mdc-text-field {
  /*height: auto !important;*/
}


/*
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
}

.mdc-dialog__title {

}

.mat-mdc-form-field .mat-mdc-form-field-icon-prefix .svg-icon {
  margin-left: 5px;
}
*/
