svg-icon {
  display: inline-block;
}

.svg-icon {
  display: inline-block;
  overflow: hidden;
  margin: 2px;

  svg {
    vertical-align: top;
    max-height: 100%;
    max-width: 100%;
  }
}

button.mat-mdc-icon-button:hover {
  color: $hover_font_color;
}


.mat-mdc-form-field-prefix {
  .svg-icon {
    margin-top: -18px;
    margin-left: -5px;
  }
}

a.mdc-icon-button {
  .svg-icon {
    margin: 8px;
  }
}
