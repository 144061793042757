$menu_bg_color: #e7ebf0;
$menu_font_color: black;



$bg_color: global white;
$bg_color2: #ecf0f5;
$font_color_1: black;
$font_color_2: #444;
$font_color_3: white;
$bar_bg_color: #3b484d;
$striped_bg_color: #f9f6f6;
$hover_bg_color: #ececec;

$hover_font_color: #C45000;

$toolbar_bg_color: #222d32;
$toolbar_bg_color_hover: #2e3c41;
$toolbar_font_color: #b8c7ce;

// #222d32 !important

$border_color: lightgrey;
$border_color2: darkgray;


$content_height: calc(var(--app-content-height));
$full_content_height: calc(var(--app-full-content-height));
