@import "../theme_vars";

.menuboxparentbox {
  height: $content_height;
  background-color: $menu_bg_color;
  color: $menu_font_color;
}

.mainmenubutton.active {
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  background-color: $menu_bg_color;
  color: $menu_font_color;
  border-bottom: 3px solid $menu_bg_color;
  margin-bottom: -3px;
}

.menuboxouter {
  margin: auto;
  max-width: 100%;

  .menubox {
    color: $menu_font_color;

    text-align: center;

    .button {
      color: $font-color_1;
      vertical-align: top;
      margin: 15px;
      width: 200px;
      height: 200px;

      > div {
        display: flex;
        flex-direction: column;
        width: 200px;
        height: 200px;
        justify-content: center;
        align-items: center;
      }

      > div.favbutton{
        position: absolute;
        top: 0;
        right: 0;
        height: 60px;
        width: 60px;
      }

      div.label1 {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: normal;
        height: 150px;
        width: 200px;
        overflow: hidden;
      }

      div.label2 {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: normal;
        height: 50px;
        width: 200px;
        overflow: hidden;
      }
    }

    .button.disabled {
      color: grey;
      background-color: white;
    }

    .button.disabled:hover {
      color: grey;
      background-color: white;
    }

    .button:hover {
      color: $hover_font_color;
      text-decoration: none;
      background-color: whitesmoke;
    }

    .button.hovertransparent:hover {
      opacity: 0.9;
    }
  }



  .menubox.menubox_small {

    .button {
      margin: 12px;
      white-space: normal;
      line-height: 12px;

      > div {
        width: 120px;
        height: 120px;
      }

      > div.favbutton{
        position: absolute;
        top: 0;
        right: 0;
        height: 30px;
        width: 30px;
      }

      div.label1 {
        height: 150px;
        width: 200px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .menuboxouter {
    .menubox {
      .button {
        margin: 11px;
        > div {
          width: 140px;
          height: 140px;
        }

        div.label1 {
          height: 120px;
          width: 140px;
        }

        div.label2 {
          height: 20px;
          width: 140px;
        }

        svg-icon {
          height: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .menuboxouter {
    .menubox {
      .button {
        margin: 11px;
      }
    }

  }
}
