html {
  --mat-text-button-state-layer-color: grey;
  --mdc-text-button-label-text-color: inherit;
  --mdc-outlined-button-label-text-color: inherit;
  --mat-toolbar-container-text-color: inherit;
  --mat-sidenav-content-background-color: #fafafa;
  --mat-app-background: #ecf0f5;
  --mat-bottom-sheet-container-background-color: white;
  --mdc-text-button-container-shape: 4px;
  --mdc-dialog-container-shape: 4px;
  --mdc-protected-button-container-shape: 4px;
  --mat-standard-button-toggle-shape: 4px;
  --mat-sidenav-container-background-color: white;
  --mat-app-surface: white;
  --mat-form-field-container-height: 46px;
  --mat-form-field-container-vertical-padding: 10px;
  --mat-menu-container-color: white;
  --mat-app-surface-container: white;
  --mdc-protected-button-label-text-color: #444;
  --mdc-icon-button-icon-color: #444;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
  --mdc-filled-button-container-shape: 4px;
  --mdc-outlined-button-container-shape: 4px;
  --mdc-protected-button-container-color: white;
  --mat-table-background-color: white;
  --mat-paginator-container-background-color: white;
  --mdc-dialog-container-color: white;
  --mat-protected-button-state-layer-color: lightgrey;
  --mdc-filled-button-state-layer-color: lightgrey;
  --mdc-outlined-button--state-layer-color: lightgrey;
  --mat-sidenav-container-divider-color: lightgrey;
  --mat-sidenav-container-shape: 0;
  --mat-bottom-sheet-container-shape: 4px;
  --mdc-snackbar-supporting-text-color: white;
  --mat-stepper-container-color: white;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-select-panel-background-color: white;
  --mat-option-selected-state-layer-color: lightgrey;
  --mdc-text-button-label-text-size: 1.1rem;
  --mdc-protected-button-label-text-size: 1.1rem;
  --mdc-filled-button-label-text-size: 1.1rem;
  --mdc-flat-button-label-text-size: 1.1rem;
  --mat-tab-header-label-text-size: 1rem;
  --mat-menu-item-label-text-size: 1rem;
  --mat-standard-button-toggle-label-text-size: 1rem;
  --mat-autocomplete-background-color: white;
  --mdc-switch-unselected-track-color: white;
  --mdc-switch-unselected-hover-track-color: white;
  --mdc-switch-unselected-focus-track-color: white;


  font-size: 14px;

  .mdc-text-field--filled {
    background-color: white !important;
  }

  .mdc-button {
    height: 46px;
  }

  mat-toolbar {
    --mdc-icon-button-icon-color: #b8c7ce;
  }

  table {
    thead, .thead {
      th {
        --mdc-checkbox-unselected-icon-color: #b8c7ce;
        --mdc-icon-button-icon-color: #b8c7ce;
      }
    }
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-title {
    border-bottom: 1px solid lightgray;
  }
}
