.search-info-box {
  display: inline-block;
  vertical-align: bottom;
  padding: 10px;
  font-size: 15px;
}

.search-info-box.newline {
  display: block;
}

.search-box {
  /*line-height: 14px;*/
  display: inline-block;
  vertical-align: bottom;
  background-color: white;

  border-radius: 5px;
  /*box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);*/
/*
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 46px;
  }

  .mdc-text-field .mdc-floating-label {
    transform: translateY(-90%);
  }

  */
  .mdc-notched-outline__trailing {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  button.reset-button {
    margin-left: -40px;
    height: 46px;
    width: 40px;
  }
/*
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-35px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.8))
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 30px;
  }*/

  button {
    vertical-align: top;
    overflow: hidden;
    height: 46px !important;
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    box-shadow: none !important;
  }

  .middlebutton {
    border-radius: 0;
  }
}
