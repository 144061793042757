.globalhelpbutton, .view_contact_button {
  button {
    width: 30px !important;
    height: 30px !important;


    .mat-mdc-button-wrapper {
      margin-top: -24px;
      position: relative;
      top: -3px
    }

    svg-icon > div {
      color: whitesmoke;
    }


  }

  .mat-mdc-icon-button {
    line-height: 28px !important;
  }

  border-radius: 16px;
  border: 1px solid #3b484d;
  background-color: #3b484d;
  display: inline-block;
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 9999;
  box-shadow: 0 0 5px grey;
}


.globalhelpbutton.mobile, .view_contact_button.mobile {
  bottom: calc(var(--app-footer-height) + var(--app-navbar-height));
}
